<template>
  <div>
    <v-container class="container--fluid">
      <v-row dense>
        <v-col cols="12">
          <v-card v-if="componentsize">
            <v-col cols="12" class="pl-0 pr-0 pb-1 mt-10">
              <v-row>
                <v-spacer />

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-select
                    v-model="santral"
                    :items="santralDataList"
                    label="Santral"
                  />
                </v-col>

                <v-col cols="3" sm="2" md="2" class="pr-3 mt-4">
                  <v-select
                    v-model="combinerboxDCCurrentModel"
                    :items="combinerboxDCCurrent"
                    label="StringBox"
                    dense
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <span
                        v-if="index === 0"
                        style="max-width: 120px"
                        class="d-inline-block text-truncate"
                        >{{ item.text }}</span
                      >
                      <span v-if="index === 1" class="grey--text text-caption"
                        >(+{{ combinerboxDCCurrentModel.length - 1 }})</span
                      >
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="Picker in menu"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker v-model="startDate" no-title scrollable>
                      <v-spacer />
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="3" sm="4" md="4" class="pr-3">
                  <v-slider
                    v-model="hoursModel"
                    :thumb-size="50"
                    thumb-label="always"
                  >
                    <template v-slot:thumb-label="{ value }">
                      {{ hourSelected[Math.min(Math.floor(value * 2))] }}
                    </template>
                  </v-slider>
                </v-col>

                <v-col cols="3" sm="1" md="1" class="pr-3">
                  <v-btn color="primary" @click="getDailyMinuteEnergy">
                    Filtrele
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-row v-if="componentsize">
              <template
                v-for="stringbox in stringBoxList[santral]"
                class="mr-5 ml-5 mb-5 mt-5"
              >
                <echart
                  v-if="livehourdata[stringbox.value]"
                  :id="stringbox.value + 'a'"
                  :key="stringbox.value"
                  class-name="widget-content mr-5 ml-5 mb-5 mt-5"
                  height="300px"
                  :width="setWith[stringbox.value].width"
                  :chart-data="livehourdata[stringbox.value]"
                />
              </template>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Echart from "@/views/components/Echart/Chart.vue";
import * as TimeOfStringbox from "@/api/CombinerBox/TimeOfStringbox";
import moment from "moment";

export default {
  name: "Dashboard",
  components: {
    Echart,
  },
  data: () => ({
    startDate: moment().format("YYYY-MM-DD"),
    finishDate: moment().format("YYYY-MM-DD"),
    dailydata: {},
    monthlydata: {},
    yearlydata: {},
    year: moment().format("YYYY"),
    menu: false,
    modal: false,
    menu2: false,
    riseze: "false",
    livehourdata: {},
    setWith: {},
    stringBoxList: {},
    combinerboxDCCurrentModel: [],

    combinerboxDCCurrent: [],
    measureList: [],
    hoursModel: 50,
    hourSelected: [],
    santralInfo: [],
    santral: "",
    stringPanelQuantity: {},
    componentsize: 1,
    component: "a",
    width: "100%",
  }),
  computed: {
    santralDataList() {
      const { santralDataList } = this.$store.getters;
      if (Object.keys(santralDataList).length > 0) {
        return santralDataList;
      }
    },
  },
  watch: {
    hoursModel() {
      this.get_getStringBoxTimeDc();
    },
    $route: function (from, to) {
      this.riseze = "true";
    },

    santral() {
      const rest = this.santralInfo[this.santral].STRINGBOX;
      const combinerboxList = [];
      const combinerboxDCCurrentModel = [];

      let i = 0;
      Object.keys(rest).forEach((key) => {
        if (i < 5) {
          combinerboxDCCurrentModel.push(rest[key].STRINGBOX);
        }
        combinerboxList.push({
          text: rest[key].STRINGBOXNAME,
          value: rest[key].STRINGBOX,
        });
        i += 1;
      });

      this.stringBoxList[this.santral] = combinerboxList;
      this.combinerboxDCCurrent = combinerboxList;
      this.combinerboxDCCurrentModel = combinerboxDCCurrentModel;

      const setWith = {};

      Object.keys(rest).forEach((k) => {
        const { stringQuantity } = rest[k];

        let width = "100%";
        if (stringQuantity <= 5) {
          width = "250px";
        } else if (stringQuantity > 5 && stringQuantity < 10) {
          width = "400px";
        } else {
          width = "100%";
        }

        setWith[k] = {
          width,
        };
      });

      this.setWith = setWith;
    },
  },
  created() {},

  mounted() {
    const myVar1 = setInterval(myTimer1, 1000);

    const self = this;
    function myTimer1() {
      const { santral } = self.$store.getters.selectedSantralModel;
      const { santralInfo, stepTime } = self.$store.getters;
      if (santral !== undefined && Object.keys(santralInfo).length > 0) {
        self.santral = santral;
        self.santralInfo = santralInfo;
        const timeList = [];
        for (let h = 5; h < 22; h++) {
          for (let mn = 0; mn < 60; mn += 5) {
            let hour;
            let mnt;

            h < 10 ? (hour = `0${h}`) : (hour = `${h}`);
            mn < 10 ? (mnt = `0${mn}`) : (mnt = `${mn}`);
            if (timeList.length < 201) {
              timeList.push(`${hour}:${mnt}`);
            }
          }
        }

        self.hourSelected = timeList;
        clearInterval(myVar1);
      }
    }
  },
  methods: {
    getDailyMinuteEnergy() {
      this.finishDate = moment(this.startDate)
        .add(1, "day")
        .format("YYYY-MM-DD");

      const payload = {
        condiniton: {
          date: this.startDate,
          startHour: 5,
          finishHour: 21,
          santral: this.santral,
          STRINGBOX: this.combinerboxDCCurrentModel,
          // column: ["STRINGBOX", "STRINGBOXKEY", "STRINGBOXNAME"],
          column: [],
        },
      };

      this.componentsize++;
      const tempData = TimeOfStringbox.default.getStringBoxTimeDc(payload);
      tempData.then((res) => {
        this.stringBoxData = res;
        this.get_getStringBoxTimeDc();
      });
    },

    get_getStringBoxTimeDc() {
      const combinerboxDCCurrentList = this.santralInfo[this.santral].STRINGBOX;

      const res = this.stringBoxData;
      const hoursModel = this.hoursModel * 2;
      const select = this.hourSelected[hoursModel];

      const optionData = TimeOfStringbox.default.get_getStringBoxTimeDc(
        res[select],
        combinerboxDCCurrentList
      );
      optionData.then((result) => {
        this.livehourdata = result;
        this.componentsize += 1;
      });
    },
  },
};
</script>
