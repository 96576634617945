import request from '../utils';
import chartOptions from '../chart/chart_options';

const getStringBoxTimeDc = (formdata) => new Promise((resolve) => {
  const url = '/panel/stringbox/timeofStringbox';

  const tempData = request.Api(url, formdata, 'post');
  tempData.then((_rawData) => {
    const minuteTimeList = {};
    Object.keys(_rawData.data.data).forEach((stringBox) => {
      Object.keys(_rawData.data.data[stringBox]).forEach((key) => {
        const string_key = 'STRINGBOX';
        // string_key = _rawData.data.data[stringBox][0].STRINGBOXNAME;
        // if (_rawData.data.data[stringBox][0].hasOwnProperty("STRINGBOXNAME")) {
        // string_key = _rawData.data.data[stringBox][0].STRINGBOXNAME;
        // }

        let hour;
        let mnt;
        const h = _rawData.data.data[stringBox][key].hour;
        const mn = _rawData.data.data[stringBox][key].minute;
        h < 10 ? (hour = `0${h}`) : (hour = `${h}`);
        mn < 10 ? (mnt = `0${mn}`) : (mnt = `${mn}`);

        const selectime = `${hour}:${mnt}`;
        if (minuteTimeList[selectime] === undefined) {
          minuteTimeList[selectime] = {};
        }

        if (minuteTimeList[selectime][stringBox] === undefined) {
          minuteTimeList[selectime][stringBox] = {};
        }
        minuteTimeList[selectime][stringBox] = _rawData.data.data[stringBox][key];
      });
    });

    resolve(minuteTimeList);
  });
});

const get_getStringBoxTimeDc = (_rawData, combinerboxDCCurrent) => new Promise((resolve) => {
  const option = {};
  const colors = ['green', 'grey'];
  const dcList = {};

  Object.keys(combinerboxDCCurrent).forEach((k) => {
    const {
      stringQuantity
    } = combinerboxDCCurrent[k];
    const dd = [];
    for (let d = 1; d <= stringQuantity; d++) {
      let dc = '';
      d < 10 ? (dc = `DCCurrent0${d}`) : (dc = `DCCurrent${d}`);
      dd.push(dc);
    }
    dcList[k] = dd;
  });

  Object.keys(_rawData).forEach((stringbox) => {
    const stringKey = combinerboxDCCurrent[stringbox].STRINGBOXNAME;
    const seriesList = [];
    const measures = ['DCCurrent', 'STRINGBOX'];
    let stringDcCurrent = '';
    let stringCurrentVal = 10;
    if (combinerboxDCCurrent[stringbox].hasOwnProperty('stringDcCurrent')) {
      stringDcCurrent = combinerboxDCCurrent[stringbox].stringDcCurrent;
      const tmp = stringDcCurrent.split(' ');
      stringCurrentVal = parseInt(tmp[0]);
    }

    Object.keys(measures).forEach((i) => {
      const data = [];
      Object.keys(_rawData[stringbox]).forEach((m) => {
        if (m.indexOf('DCCurrent') > -1) {
          if (measures[i] === 'STRINGBOX') {
            const tmpVal = (stringCurrentVal - _rawData[stringbox][m]).toFixed(2);
            data.push(parseFloat(tmpVal));
          } else {
            const val = parseFloat(_rawData[stringbox][m].toFixed(2));
            data.push(val);
          }
        }
      });

      seriesList.push({
        name: measures[i],
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
        },
        emphasis: {
          focus: 'series',
        },
        data,
      });
    });

    option[stringbox] = chartOptions.line_option(dcList[stringbox], seriesList, stringKey,
      measures, colors);
  });

  resolve(option);
});

export default {
  getStringBoxTimeDc,
  get_getStringBoxTimeDc,
};
